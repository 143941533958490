import React from "react";

const Auth = React.lazy(() => import("../views/Auth/index"));
const FlowBuilderView = React.lazy(() => import("../views/FlowBuilder/index"));
const HomeView = React.lazy(() => import("../views/Home/index"));
const MagicLoginView = React.lazy(() => import("../views/MagicLogin/index"));

const routes = {
  auth: {
    path: "/:portalSlug/auth",
    component: Auth,
    roles: [],
    exact: false,
    routes: {
      login: {
        exact: true,
        path: "/:portalSlug/auth/login",
      },
      register: {
        exact: true,
        path: "/:portalSlug/auth/register",
      },
    },
  },
  workflow: {
    path: "/:portalSlug/home",
    component: HomeView,
    roles: ["USER"],
    exact: true,
    showBackgroundImage: false
  },
  form: {
    path: "/:portalSlug/flow/:workflowSlug/:requestId",
    component: FlowBuilderView,
    roles: ["USER"],
    exact: true,
  },
  magicLink: {
    path: "/:portalSlug/magic-link/:token",
    component: MagicLoginView,
    roles: [],
    exact: true,
  },
};

export default routes;
