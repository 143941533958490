export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const PUBLIC_PORTAL = `${SERVER_URL}/v1/public/portal`;
const PRIVATE_PORTAL = `${SERVER_URL}/v1/private/portal`;

export const URLS = {
  LOGIN: `${PUBLIC_PORTAL}/login`,
  LOGIN_VIA_OTP: `${PUBLIC_PORTAL}/login/via-otp`,
  SEND_OTP: `${PUBLIC_PORTAL}/login/send-otp`,
  CLIENT_BASIC_DATA: `${PUBLIC_PORTAL}/info/subdomain`,
  WORKFLOWS: `${PRIVATE_PORTAL}/workflows`,
  WORKFLOW_DATA: `${PRIVATE_PORTAL}/workflows/data`,
  STEP_NEXT_CLICK: `${PRIVATE_PORTAL}/workflows/next`,
  UPLOAD_FILE: `${PRIVATE_PORTAL}/upload-file`,

  /* LAYOUT */
  LAYOUT: `${PRIVATE_PORTAL}/layout`,

  LOGOUT: `${PUBLIC_PORTAL}/logout`,
  REGISTER: `${PUBLIC_PORTAL}/register`,
  VERIFY_EMAIL: `${PUBLIC_PORTAL}/verify/email`,
  GOOGLE_CONNECT: `${PUBLIC_PORTAL}/google-connect`,
  GET_SESSION: `${PUBLIC_PORTAL}/session`,
  CHECK_USERNAME: `${PUBLIC_PORTAL}/check/username`,
  CHANGE_USERNAME: `${PUBLIC_PORTAL}/change/username`,
};
